@use '@angular/material' as mat;

@use './m3-monochrome-theme' as m3-theme;

@include mat.core();

html {
    // Apply the light theme by default
    @include mat.all-component-themes(m3-theme.$light-theme);

    //@include mat.color-variants-backwards-compatibility(m3-theme.$light-theme);

    /* Permet d'appliquer les classes de typographie de Material Design : https://material.angular.io/guide/material-2-theming#optional-add-backwards-compatibility-styles-for-typography-hierarchy
     * Les types scales possible : https://material.angular.io/guide/typography#type-scale-levels
     */
    @include mat.typography-hierarchy(m3-theme.$light-theme, $back-compat: true);
}

/**
* Tailwind CSS
*/
@tailwind base;
@tailwind components;
@tailwind utilities;

/**
* Custom styles
*/

html,
body {
    height: 100%;
    margin: 0;
}

:root {
    --primary-10: #{mat.get-theme-color(m3-theme.$light-theme, primary, 99)};
    --primary-20: #{mat.get-theme-color(m3-theme.$light-theme, primary, 98)};
    --primary-50: #{mat.get-theme-color(m3-theme.$light-theme, primary, 95)};
    --primary-100: #{mat.get-theme-color(m3-theme.$light-theme, primary, 90)};
    --primary-200: #{mat.get-theme-color(m3-theme.$light-theme, primary, 80)};
    --primary-300: #{mat.get-theme-color(m3-theme.$light-theme, primary, 70)};
    --primary-400: #{mat.get-theme-color(m3-theme.$light-theme, primary, 60)};
    --primary-500: #{mat.get-theme-color(m3-theme.$light-theme, primary, 50)};
    --primary-600: #{mat.get-theme-color(m3-theme.$light-theme, primary, 40)};
    --primary-700: #{mat.get-theme-color(m3-theme.$light-theme, primary, 30)};
    --primary-800: #{mat.get-theme-color(m3-theme.$light-theme, primary, 20)};
    --primary-900: #{mat.get-theme-color(m3-theme.$light-theme, primary, 10)};

    --secondary-10: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 99)};
    --secondary-20: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 98)};
    --secondary-50: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 95)};
    --secondary-100: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 90)};
    --secondary-200: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 80)};
    --secondary-300: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 70)};
    --secondary-400: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 60)};
    --secondary-500: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 50)};
    --secondary-600: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 40)};
    --secondary-700: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 30)};
    --secondary-800: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 20)};
    --secondary-900: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 10)};

    --warn: #{mat.get-theme-color(m3-theme.$light-theme, error)};

    --on-surface-color: #{mat.get-theme-color(m3-theme.$light-theme, on-surface)};
    --outline-variant: #{mat.get-theme-color(m3-theme.$light-theme, neutral-variant, 80)};

    --surface-container: #{mat.get-theme-color(m3-theme.$light-theme, neutral, 94)};
    --surface-container-low: #{mat.get-theme-color(m3-theme.$light-theme, neutral, 96)};
    --surface-bright: #{mat.get-theme-color(m3-theme.$light-theme, neutral, 98)};
    --surface: #{mat.get-theme-color(m3-theme.$light-theme, neutral, 98)};
    --secondary-container: #{mat.get-theme-color(m3-theme.$light-theme, secondary, 90)};
    // Surcharge des variables Angular Material
    --mdc-outlined-text-field-outline-color: var(--outline-variant);
    --mat-table-row-item-outline-color: var(--outline-variant);
}

// La propriété color n'est pas encore nativement supporté pour "error" par angular material M3
[color='error'] {
    @include mat.button-color(m3-theme.$light-theme, $color-variant: error);
}

// La propriété color n'est pas encore nativement supporté pour "secondary" par angular material M3
[color='secondary'] {
    @include mat.button-color(m3-theme.$light-theme, $color-variant: secondary);
}

// La propriété color n'est pas encore nativement supporté pour "tertiary" par angular material M3
[color='tertiary'] {
    @include mat.button-color(m3-theme.$light-theme, $color-variant: tertiary);
}

.mdc-notched-outline__notch {
    border-right: 0;
}

.mat-mdc-text-field-wrapper {
    background-color: var(--surface-bright);
}

mat-paginator {
    @apply rounded-b-xl;
    display: flex;
    @apply justify-center;
}

.regulation-action-dialog {
    --mdc-dialog-container-shape: 0;

    .mat-mdc-dialog-content {
        max-height: unset;
    }
}

.custom-background-gradient {
    background-image: linear-gradient(
        45deg,
        #0c3672 0%,
        /* Bleu marine */ #0077b6 30%,
        /* Transition bleu */ #00a8e1 50%,
        /* Bleu clair */ #ffb800 100%,
        /* Jaune */
    );
}
